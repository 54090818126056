import React, { useEffect, useState } from "react";
import SessionDeletePopUp from "../components/Modals/SessionDeletePopUp";
import { Card, Space } from "antd";
import moment from "moment";
import { Form } from "antd";
import { NavLink, useParams } from "react-router-dom";
import { getById } from "services/coachee.service";
import {
  getAllWithFilters,
  getCoachSessionsWithFilters,
  getAllNoShowSessions,
  getSearchByTextNoShow,
  GetSessionsWithFiltersNoShow,
  coachSessionsWithNoShow,
  coacheeSessionsWithNoShow,
} from "services/session.service";
import { updateSession } from "services/sessions.service";
import { DateTime } from "luxon";
import "moment/locale/es";
import NewTable from "components/Table/NewTable";

import { Excel } from "antd-table-saveas-excel";
import useFetchAndLoad from "../../../hooks/useFetchAndLoad";

import Title from "common/Title/Title";
import FiltersButton from "../components/Buttons/FiltersButton";
import FilterOptions from "../components/Buttons/FilterOptions";
import ModalCoach from "../components/Modals/ModalCoach";
import ModalCoachee from "../components/Modals/ModalCoachee";
import displayToast from "utilities/toast.utility";
import { Switch } from "@chakra-ui/react";

function NoShowSessions() {
  moment.locale("es");

  const [form] = Form.useForm();

  const [showModal, setShowModal] = React.useState(false);

  const { loading, callEndpoint } = useFetchAndLoad();
  const { id } = useParams();

  const [componentDisabled, setComponentDisabled] = useState<boolean>(false);
  const [sessions, setSessions] = useState([]);
  const [sessionsToExport, setSessionsToExport] = useState([]);
  const [total, setTotal] = useState(0);
  const [sessionId, setSessionId] = useState(null);
  const [text, setText] = useState("");

  const [filteredData, setFilteredData] = useState<any[]>([]);

  const handleDelete = (id) => {
    setSessionId(id);
    setShowModal(true);
  };

  const dataSource_sessiones =
    sessions.length > 0
      ? sessions.sort((a, b) => a.sessionNumber - b.sessionNumber)
      : null;

  const format = "dd 'de' LLLL 'de' yyyy, h:mm a";

  const columns_sessiones = [
    {
      title: "",
      dataIndex: "noShow",
      align: "center",
      width: "8%",
      render: (_, record) => (
        <div>
          {record.noShow && (
            <span className="bg-red-200 text-red-600 py-1 px-3 rounded-full">
              No Show
            </span>
          )}
        </div>
      ),
    },
    {
      title: "No Show",
      dataIndex: "noShow",
      align: "center",
      width: "10%",
      render: (_, record) => (
        <div className="">
          <Switch
            size={"lg"}
            defaultChecked={false}
            isChecked={record.noShow}
            value={record.noShow}
            onChange={(e) => {
              handleState(record._id, record.noShow ? false : true);
            }}
          />
        </div>
      ),
    },
    {
      title: "Fecha de sesión",
      dataIndex: "date",
      align: "center",
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: (date) =>
        date
          ? DateTime.fromISO(date, {
              zone: coachee?.timezone,
            })
              .setLocale("es")
              .toFormat(format)
          : "n/a",
    },
    {
      title: "Coach",
      dataIndex: "coach",
      align: "center",
      render: (coach) =>
        coach?.name && coach?.lastname ? (
          <span>
            {coach.name} {coach.lastname}
          </span>
        ) : coach?.name && !coach?.lastname ? (
          <span>{coach.name}</span>
        ) : !coach?.name && coach?.lastname ? (
          <span>{coach.lastname}</span>
        ) : (
          ""
        ),
    },
    {
      title: "Coachee",
      dataIndex: "coachee",
      align: "center",
      render: (coachee) =>
        coachee?.name && coachee?.lastname ? (
          <span>
            {coachee?.name} {coachee?.lastname}
          </span>
        ) : coachee?.name && !coachee?.lastname ? (
          <span>{coachee?.name}</span>
        ) : !coachee?.name && coachee?.lastname ? (
          <span>{coachee?.lastname}</span>
        ) : (
          ""
        ),
    },
    {
      title: "Status",
      dataIndex: "da",
      align: "center",
      zIndex: "revert",
      sorter: (a, b) => a - b,
      render: (_, record) =>
        record.status === true && record.canceled === true ? (
          <span className="bg-yellow-200 text-yellow-600 py-1 px-3 rounded-full">
            Finalizada y Cancelada
          </span>
        ) : record.status === true ? (
          <span className="bg-green-200 text-green-600 py-1 px-3 rounded-full">
            Finalizada
          </span>
        ) : record.canceled === true ? (
          <span className="bg-red-200 text-red-600 py-1 px-3 rounded-full">
            Cancelada
          </span>
        ) : (
          <span className="bg-yellow-200 text-yellow-600 py-1 px-3 rounded-full">
            Agendada
          </span>
        ),
    },
    {
      title: "Acciones",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Space size="middle">
            {}
            <NavLink to={`/coachee/session/view/${record._id}`}>
              <div className="w-10 mr-2 transform hover:text-[#1890ff] hover:scale-110">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
              </div>
            </NavLink>
          </Space>
          <Space size="middle">
            {}
            <NavLink to={`/coachee/session/edit/${record._id}`}>
              <div className="w-10 mr-2 transform hover:text-[#1890ff] hover:scale-110">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  />
                </svg>
              </div>
            </NavLink>
            {/* Editar */}
          </Space>
          <Space size="middle">
            {}
            <button
              className="text-black font-semibold"
              type="button"
              onClick={() => handleDelete(record._id)}
            >
              <div className="w-10 mr-2 transform hover:text-[#1890ff] hover:scale-110">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </div>
            </button>
            {showModal ? (
              <SessionDeletePopUp
                setShowModal={setShowModal}
                sessionId={sessionId}
                refresh={refresh}
              />
            ) : null}
          </Space>
        </div>
      ),
    },
  ];

  const [coachee, setCoachee] = useState(null);

  const [finalizada, setFinalizada] = useState(false);
  const [cancelada, setCancelada] = useState(false);
  const [agendada, setAgendada] = useState(false);
  const [fechaInicial, setFechaInicial] = useState(null);
  const [fechaFinal, setFechaFinal] = useState(null);

  const getCoachee = () => {
    callEndpoint(getById(id)).then(({ data }) => {
      setCoachee(data.data);
    });
  };

  const getSessions = (page: number = 1, pageSize: number = 10) => {
    setSessions([]);
    setTotal(0);
    callEndpoint(getAllNoShowSessions(page, pageSize)).then(({ data }) => {
      setSessions(
        data?.data?.result.data.map((x, i) => {
          x.key = x._id;
          return x;
        })
      );

      setTotal(data?.data.total);
    });
  };

  useEffect(() => {
    getCoachee();
  }, []);

  useEffect(() => {}, [loading]);
  useEffect(() => {
    getSessions();
  }, []);

  const [coachId, setCoachId] = useState(null);
  const [coacheeId, setCoacheeId] = useState(null);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [selectedCoachee, setSelectedCoachee] = useState(null);
  const [searchText, setSearchText] = useState("");

  const onChange = async (page, pageSize) => {
    if (coacheeId !== null) {
      const { data } = await callEndpoint(
        getCoachSessionsWithFilters(page, pageSize, coacheeId)
      );
      setSessions(
        data?.data?.result.data.map((x, i) => {
          x.key = x._id;
          return x;
        })
      );
      setTotal(data?.data.total);
      return;
    } else if (filteredData.length > 0) {
      const { data } = await callEndpoint(
        getAllWithFilters(page, pageSize, searchText, {
          ...filteredData[0],
          text: searchText,
        })
      );
      setSessions(
        data?.data?.result.data.map((x, i) => {
          x.key = x._id;
          return x;
        })
      );
      setTotal(data?.data.total);
    } else if (selectedCoach) {
      const { data } = await callEndpoint(
        coachSessionsWithNoShow(page, pageSize, selectedCoach)
      );

      if (data.data.total > 0) {
        setSessions(data.data.result.data);
        setTotal(data.data.total);
      }
    } else if (selectedCoachee) {
      const { data } = await callEndpoint(
        coacheeSessionsWithNoShow(page, pageSize, selectedCoachee)
      );

      if (data.data.total > 0) {
        setSessions(data.data.result.data);
        setTotal(data.data.total);
      }
    } else {
      getSessions(page, pageSize);
    }
  };

  const [filters, setFilters] = useState({
    finalizada,
    cancelada,
    agendada,
    fechaInicial,
    fechaFinal,
  });

  const makeSearchTable = async (filters, text) => {
    try {
      setSessions([]);
      const { data } = await callEndpoint(
        GetSessionsWithFiltersNoShow(1, 10, { ...filters, text })
      );

      setSessions((prevSessions) => [
        ...prevSessions,
        ...data?.data.result.data.map((x, i) => {
          x.key = x._id;
          return x;
        }),
      ]);

      setTotal(data?.data.total);

      if (data?.data?.result?.data?.length > 0) {
        if (filteredData.length >= 1) {
          setFilteredData([]);
          setFilteredData((prevFilters) => [...prevFilters, filters]);
        }

        setFilteredData((prevFilters) => [...prevFilters, filters]);
      }

      if (data) {
        const { data } = await callEndpoint(
          GetSessionsWithFiltersNoShow(1, 9999, { ...filters, text })
        );

        setSessionsToExport(data.data.result.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const makeSearchByText = async (text) => {
    try {
      setSessions([]);
      const { data } = await callEndpoint(getSearchByTextNoShow(1, 10, text));

      setSessions((prevSessions) => [
        ...prevSessions,
        ...data?.data.result.map((x, i) => {
          x.key = i;
          return x;
        }),
      ]);

      setTotal(data?.data.total);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (searchText.trim() !== "") {
      makeSearchByText(searchText);
    }
  }, [searchText]);

  useEffect(() => {
    if (searchText.length === 0) {
      getSessions();
    }
  }, [searchText]);

  const refresh = () => {
    setText("");
    setSelectedCoach(null);
    setApplyFilters(false);
    setFilters({
      finalizada: false,
      cancelada: false,
      agendada: false,
      fechaInicial: null,
      fechaFinal: null,
    });
    setFilteredData([]);
    getSessions();
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [SelectedItems, setSelectedItems] = useState<any[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    const arr = [];
    const result = sessions.filter((x) => newSelectedRowKeys.includes(x._id));
    for (const item of result) {
      arr.push({
        date: DateTime.fromISO(item.date, { zone: coachee?.timezone })
          .setLocale("es")
          .toFormat(format),
        coachFullName: `${item?.coach?.name} ${item?.coach?.lastname}`,
        coachEmail: item.coach?.email,
        coacheeFullName: `${item?.coachee?.name} ${item?.coachee?.lastname}`,
        coacheeEmail: item?.coachee?.email,
        finalizada: item.status ? "SI" : "NO",
        cancelada: item.canceled ? "SI" : "NO",
      });
    }
    setSelectedItems(arr);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columnsExport = [
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Coach",
      dataIndex: "coachFullName",
      key: "coachFullName",
    },
    {
      title: "Coach Email",
      dataIndex: "coachEmail",
      key: "coachEmail",
    },
    {
      title: "Coachee",
      dataIndex: "coacheeFullName",
      key: "coacheeFullName",
    },
    {
      title: "Coachee Email",
      dataIndex: "coacheeEmail",
      key: "coacheeEmail",
    },
    {
      title: "Finalizada",
      dataIndex: "finalizada",
      key: "finalizada",
    },
    {
      title: "Cancelada",
      dataIndex: "cancelada",
      key: "cancelada",
    },
  ];

  const formattedSessions = sessionsToExport.map((session) => ({
    date: DateTime.fromISO(session.date, { zone: coachee?.timezone })
      .setLocale("es")
      .toFormat(format),
    coachFullName: `${session?.coach?.name} ${session?.coach?.lastname}`,
    coachEmail: session.coach?.email,
    coacheeFullName: `${session?.coachee?.name} ${session?.coachee?.lastname}`,
    coacheeEmail: session?.coachee?.email,
    finalizada: session.status ? "SI" : "NO",
    cancelada: session.canceled ? "SI" : "NO",
  }));

  const handleClick = () => {
    const excel = new Excel();

    excel
      .addSheet("sessions")
      .addColumns(columnsExport)
      .addDataSource(SelectedItems, {
        str2Percent: true,
      })
      .saveAs("sessions.xlsx");
  };

  // export all sessions

  const exportAllData = () => {
    const excel = new Excel();

    excel
      .addSheet("sessions")
      .addColumns(columnsExport)
      .addDataSource(formattedSessions, {
        str2Percent: true,
      })
      .saveAs("allsessions.xlsx");
  };

  const hasSelected = selectedRowKeys.length > 0;

  const [coachModal, setCoachModal] = useState(false);
  const [coacheeModal, setCoacheeModal] = useState(false);
  const [applyFilters, setApplyFilters] = useState(false);

  const handleState = async (id: string, state: boolean) => {
    try {
      await callEndpoint(updateSession({ id, noShow: state }));
      const index = sessions.findIndex((x) => x._id === id);
      if (sessions[index]) {
        sessions[index].noShow = state;
        setSessions(sessions);
      }
      getSessions();
      displayToast("Sesion actualizada correctamente.", "success");
    } catch (error) {
      displayToast("Paso algo.", "error");
    }
  };

  return (
    <Card>
      <Title title="Sesiones" />
      <div className="flex justify-start items-center mb-6 pl-5">
        <input
          type="text"
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className="input input-bordered w-3/4 h-20 rounded-3xl placeholder:text-gray-700 text-lg focus:ring focus:placeholder:text-cyan-700 focus:border-blue-200 focus:outline-none"
        />

        <FiltersButton
          setFechaInicial={setFechaInicial}
          setFechaFinal={setFechaFinal}
          fechaInicial={fechaInicial}
          fechaFinal={fechaInicial}
          setFinalizada={setFinalizada}
          setCancelada={setCancelada}
          setAgendada={setAgendada}
          finalizada={finalizada}
          cancelada={cancelada}
          agendada={agendada}
          makeSearchTable={makeSearchTable}
          setApplyFilters={setApplyFilters}
          setFilters={setFilters}
          setCoachModal={setCoachModal}
          setCoacheeModal={setCoacheeModal}
          noShow={true}
        />
      </div>

      {coachModal ? (
        <ModalCoach
          setCoachModal={setCoachModal}
          setSessions={setSessions}
          setTotal={setTotal}
          setCoachId={setCoachId}
          setSelectedCoach={setSelectedCoach}
          selectedCoach={selectedCoach}
          noShow={true}
          setSessionsToExport={setSessionsToExport}
        />
      ) : null}

      {coacheeModal ? (
        <ModalCoachee
          setCoacheeModal={setCoacheeModal}
          setSessions={setSessions}
          setTotal={setTotal}
          setCoacheeId={setCoacheeId}
          setSelectedCoachee={setSelectedCoachee}
          selectedCoachee={selectedCoachee}
          noShow={true}
          setSessionsToExport={setSessionsToExport}
        />
      ) : null}

      {applyFilters ? (
        fechaInicial || fechaFinal || finalizada || agendada || cancelada ? (
          <div className="flex justify-between">
            <div className="flex ml-20 mb-12 space-x-4">
              <FilterOptions
                setFechaInicial={setFechaInicial}
                setFechaFinal={setFechaFinal}
                fechaInicial={fechaInicial}
                fechaFinal={fechaInicial}
                setFinalizada={setFinalizada}
                setCancelada={setCancelada}
                setAgendada={setAgendada}
                finalizada={finalizada}
                cancelada={cancelada}
                agendada={agendada}
                makeSearchTable={makeSearchTable}
                setFilters={setFilters}
                filters={filters}
                refresh={refresh}
              />
            </div>
          </div>
        ) : null
      ) : null}

      <div className="flex justify-between">
        <div className="flex ml-20 space-x-4">
          {/* <Export handleClick={handleClick} hasSelected={hasSelected} /> */}
          {/* <Export2 handleClick={handleClick} hasSelected={hasSelected} />

          <ExportAll exportAllData={exportAllData} hasSelected={hasSelected} /> */}
        </div>
      </div>

      <Form
        className="flex items-center"
        layout="vertical"
        form={form}
        name="AddCoach control-ref"
        disabled={componentDisabled}
      >
        <div className="flex pl-5 column w-100">
          <NewTable
            loading={loading}
            originData={dataSource_sessiones}
            columns={columns_sessiones}
            total={total}
            onChange={onChange}
            exportMethod={handleClick}
            exportAllData={exportAllData}
            rowSelection={rowSelection}
            hasSelected={hasSelected}
          />
        </div>
      </Form>
    </Card>
  );
}

export default NoShowSessions;
