import axios from "axios";
import { loadAbort } from "utilities";

const sessionsURL = process.env.REACT_APP_API_SESSION_URL + "session";
const alignmentSessionsURL =
  process.env.REACT_APP_API_SESSION_URL + "alignmentSession";

export const createSession = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.post(`${sessionsURL}`, data, { signal: controller.signal }),
    controller,
  };
};

export const createAlignmentSession = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.post(`${alignmentSessionsURL}/addAlignmentSession`, data, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const getAllAlignmentSessionCoachee = (id) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(`${alignmentSessionsURL}/coachee/${id}`, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const EndSession = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${sessionsURL}/EndSession/${data._id || data.id}`, data, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const AlternalCall = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.patch(`${sessionsURL}/AlternalCall`, data, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const updateSession = (data) => {
  const controller = loadAbort();
  console.log("data", data);

  return {
    call: () =>
      axios.put(`${sessionsURL}/${data._id || data.id}`, data, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const getCoachSessions = (coach) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.get(`${sessionsURL}/coach/${coach}`, { signal: controller.signal }),
    controller,
  };
};

export const getCoacheeSessions = (coachee) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.get(`${sessionsURL}/coachee/${coachee}`, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const getSessionByID = (id) => {
  const controller = loadAbort();

  return {
    call: () => axios.get(`${sessionsURL}/${id}`),
    controller,
  };
};

export const cancelSession = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${sessionsURL}/cancel/${data.id}`, data, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const cancelSessionByCoach = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${sessionsURL}/coach/cancel/${data._id}`, data, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const rescheduleSession = (id, data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${sessionsURL}/reschedule/${id}`, data, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const updateSessionStatus = (params) => {
  const controller = loadAbort();

  const { id, newStatus } = params;

  console.log("newStatus", newStatus);

  return {
    call: () =>
      axios.put(
        `${sessionsURL}/updateSessionStatus/${id}`,
        { newStatus },
        {
          signal: controller.signal,
        }
      ),
    controller,
  };
};
